import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  BodyRenderer,
  ContactForm,
  ContactPerson,
  TabsNavigation
} from 'components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import useFilterLocale from 'hooks/useFilterLocale'
import React, { useMemo } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

const Image = styled(Img)`
  width: 100%;
`
const useStyles = makeStyles(theme => ({
  pageheading: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 50,
    letterSpacing: 5
  },

  mainContainer: {
    padding: '3%',

    position: 'relative',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-end'
    }
  },
  mainHeading: {
    textTransform: 'uppercase',
    fontSize: 90,
    fontWeight: 'bold',
    letterSpacing: '20px',
    position: 'absolute',
    left: '39%',
    top: '15%',
    display: 'none',
    [theme.breakpoints.between('lg', 'md')]: {
      color: 'red'
    },

    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 60,
      top: '10%'
    }
  },

  mobileheading: {
    textTransform: 'uppercase',
    fontSize: 70,
    fontWeight: 'bold',
    letterSpacing: '10px',
    textAlign: 'center',
    margin: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      letterSpacing: '0px',
      fontWeight: 'bold',
      textAlign: 'center',
      margin: 0
    }
  },
  subline: {
    fontWeight: 'bold',
    fontFamily: 'Baskerville',
    margin: '40px 0px',
    fontSize: 50,
    lineHeight: '60px',

    [theme.breakpoints.up('lg')]: {
      padding: '0 50px '
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
      fontSize: 30,
      margin: 0,
      textAlign: 'center'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 30,
      margin: '20px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
      margin: 5,
      lineHeight: '40px'
    }
  },
  imageContainer: {
  },
  locationImage: {
    minHeight: 1200
  },
  descriptionContainer: {
    margin: 'auto 0',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  description: {
    fontSize: 18,
    [theme.breakpoints.up('lg')]: {
      padding: '0 50px '
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 14
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  inquiry: {
    fontFamily: 'Baskerville',
    fontWeight: 'bold',
    fontSize: '40px',
    marginLeft: '20px'
  },
  personHeadline: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  contactInfo: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}))

function Location(props) {
  const {
    pageContext: { locale, node_locale },
    data: { allLocations, location }
  } = props

  const classes = useStyles()

  const links = useMemo(
    () =>
      allLocations.edges.map(edge => ({
        ...edge.node,
        title: edge.node.locationName
      })),
    [allLocations]
  )

  const filteredLinks = useFilterLocale(links)

  return (
    <Container maxWidth="xl">
      <Helmet
        description={location.metaDescription}
        htmlAttributes={{ lang: node_locale || locale }}
        title={location.metaTitle}
      >
        <meta content={location.metaDescription} name="description" />
        <meta content={location.metaTitle} name="title" />
      </Helmet>{' '}
      <TabsNavigation links={filteredLinks} />
      <Grid container className={classes.mainContainer} spacing={2}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Box className={classes.imageContainer}>
            <Image alt={location.photo.title} className={classes.locationImage} fluid={location.photo.fluid} />
          </Box>
        </Grid>
        <Box>
          <Typography className={classes.mainHeading} variant="h1">
            {location.locationName}
          </Typography>
        </Box>
        <Hidden only={['lg', 'xl']}>
          <Grid item xs={12}>
            <Typography
              align="center"
              className={classes.mobileheading}
              variant="h1"
            >
              {location.locationName}
            </Typography>
          </Grid>
        </Hidden>

        <Grid
          item
          className={classes.descriptionContainer}
          lg={6}
          md={12}
          sm={12}
        >
          <Typography className={classes.subline} variant="h2">
            {location.subline}
          </Typography>
          <Box className={classes.description}>
            <BodyRenderer json={location.description.json} />
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Grid container className={classes.contactInfo}>
        <Grid item lg={4} md={6} sm={12}>
          <ContactForm />
        </Grid>

        <Grid item sm={4}>
          <Typography variant="h3">{location.contactPersonHeadline}</Typography>
          <ContactPerson
            email={location.contactPerson.email}
            name={location.contactPerson.name}
            photo={location.contactPerson.photo}
            telephone={location.contactPerson.telephone}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Location

export const locationsQuery = graphql`
  query($slug: String!, $locale: String!) {
    allLocations: allContentfulLocation(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          slug
          node_locale
          locationName
        }
      }
    }
    location: contentfulLocation(slug: { eq: $slug }) {
      metaTitle
      metaDescription
      locationName
      slug
      subline
      node_locale
      contactPersonHeadline
      description {
        json
      }
      photo {
        fluid(maxWidth: 600, quality: 70) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
      contactPerson {
        ... on ContentfulContactPerson {
          name
          email
          telephone
          photo {
            file {
              url
            }
            title
          }
        }
      }
    }
  }
`
