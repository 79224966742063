import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Container from "@material-ui/core/Container";
import { usePageContext } from "components/PageContext";
import { Link } from "gatsby";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    borderBottom: "1px solid #ebebeb",
    width: "auto",
    display: "flex",
    justifyContent: "center",
    marginTop: "50px"
  },
  tabRoot: {
    textTransform: "capitalize",
    fontSize: 18,
    fontFamily: "Barlow",
    margin: "0px 5px"
  }
}));

function TabsNavigation(props) {
  const { links } = props;
  const pageContext = usePageContext();
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Tabs
        centered
        indicatorColor="primary"
        scrollButtons="auto"
        value={pageContext.id}
        variant="scrollable"
      >
        {links.map(link => (
          <Tab
            key={link.id}
            component={Link}
            label={link.title}
            to={link.to}
            value={link.id}
            classes={{ root: classes.tabRoot }}
          />
        ))}
      </Tabs>
    </Paper>
  );
}

export default TabsNavigation;
